import React, { useEffect } from "react";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AOS from "aos";
import "aos/dist/aos.css";

import logo from '../static/queue-logo.png';
import texture from '../static/black-texture.jpg';

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  });

  return (
    <>
<Grid
  container
  spacing={0}
  direction="column"
  alignItems="center"
  justify="center"
  style={{ minHeight: '100vh', backgroundImage: `url(${texture})`,     backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat' }}
>

  <Grid item xs={11} sm={7} md={3} lg={2}>
    <img style={{maxWidth: '100%'}} src={logo} alt="logo" />
  </Grid>   
  <Typography variant='h6'>Full site coming soon.</Typography>

</Grid> 
 </>
  );
}

export default App;
