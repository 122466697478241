import React from "react";
import { Switch, Route } from "react-router";
import PageTracker from "./components/GoogleAnalytics";
import HomePage from "./components/App";

import routes from "./constants/routes";

export default () => (
  <>
    <PageTracker options={{}} />
    <Switch>
      <Route path={routes.HOME} component={HomePage} />
    </Switch>
  </>
);
